import { jsPDF } from "jspdf";
import "jspdf-autotable";
import Moment from "moment";

export default {
  name: "TSavingAccountDetailBookTransaction",
  data() {
    return {
      validationDomain: "",
      identity: {
        nama: "",
        saldoTerakhir: "",
        accountNumber: "",
      },
      identifier: {
        bookRow: 33,
      },
      form: {
        checkingSavingAccount: {
          accountNumber: "",
          cifIdName: "",
        },
      },
      session: {
        checkingSavingAccount: {
          accountNumber: "",
          cifIdName: "",
        },
      },
      property: {
        listElement: {
          bookTransaction: {
            message: "",
            downloading: false,
          },
          savingAccount: {
            rows: 0,
            perPage: 5,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
        modal: {
          showModalCheckingSavingAccount: false,
          showModalResultListSavingAccount: false,
        },
        animation: {
          CheckingSavingAccount: {
            isLoading: false,
          },
          isDownloadingFile: false,
        },
        filterDto: {
          accountNumber: "",
          dateFrom: "",
          cifIdName: "",
          dateTo: "",
          startRow: 0,
          balanceEndRow: 0,
          endRow: 33,
        },
      },
      table: {
        data: {
          transaction: [],
          pdf: [],
          savingAccount: [],
        },
      },
    };
  },

  methods: {
    async onDownloadFile(fileType) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        fileType: fileType,
        accountNumber: this.property.filterDto.accountNumber,
        dateFrom: this.property.filterDto.dateFrom,
        dateTo: this.property.filterDto.dateTo,
        startPrintFromLine: this.property.filterDto.startRow,
        totalData: this.property.filterDto.endRow,
      };
      this.property.animation.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "report",
          reqUrl: "book-transaction/files",
          payload: payload,
        });
        if (fileType === "pdf") {
          this.printOutWindow(resp.data);
          return;
        }
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Cetak_Transaksi_Buku.${
          fileType === "xls" ? "xlsx" : "pdf"
        }`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        this.property.animation.isDownloadingFile = false;
      }
    },
    closeModalCheckingSavingAccount() {
      this.property.modal.showModalCheckingSavingAccount = false;
      this.clearModalCheckingSavingAccount();
    },
    clearModalCheckingSavingAccount() {
      this.form.checkingSavingAccount.accountNumber = "";
    },
    handleErrorGetSavingAccount(error) {
      console.log(error.response);
      this.table.data.savingAccount = [];
      this.property.listElement.savingAccount.rows = 0;
      this.property.listElement.savingAccount.currentPage = 1;
      this.property.listElement.savingAccount.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async checkingAvailabilitySavingAccount() {
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.session.checkingSavingAccount.accountNumber = this.form.checkingSavingAccount.accountNumber;
        this.session.checkingSavingAccount.cifIdName = this.form.checkingSavingAccount.cifIdName;
        this.form.checkingSavingAccount.accountNumber = "";
        this.form.checkingSavingAccount.cifIdName = "";

        try {
          this.property.animation.CheckingSavingAccount.isLoading = true;
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "transaction",
            reqUrl: "saving-account/cross-office/simple-list",
            payload: {
              accountNumber: this.session.checkingSavingAccount.accountNumber,
              idCardNumber: "",
              cifIdName: this.session.checkingSavingAccount.cifIdName,
              page: 0,
              size: this.property.listElement.savingAccount.perPage,
            },
          });
          await this.simpleWait(500);
          if (resp.data.code === "SUCCESS") {
            this.table.data.savingAccount = resp.data.data.content;
            this.property.modal.showModalCheckingSavingAccount = false;
            this.property.modal.showModalResultListSavingAccount = true;
            this.property.listElement.savingAccount.rows =
              resp.data.data.totalElements;
          } else if (resp.data.code === "DATA NOT FOUND") {
            this.session.checkingSavingAccount.accountNumber = this.form.checkingSavingAccount.accountNumber;
            this.form.checkingSavingAccount.accountNumber = "";
            this.session.checkingSavingAccount.cifIdName = this.form.checkingSavingAccount.cifIdName;
            this.form.checkingSavingAccount.cifIdName = "";
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: `Nomor Rekening Tidak Tersedia!`,
              confirmText: "Ok",
              type: "is-danger",
            });
          } else {
            this.session.checkingSavingAccount.accountNumber = this.form.checkingSavingAccount.accountNumber;
            this.form.checkingSavingAccount.accountNumber = "";
            this.session.checkingSavingAccount.cifIdName = this.form.checkingSavingAccount.cifIdName;
            this.form.checkingSavingAccount.cifIdName = "";
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          console.log(error.response);
          await this.simpleWait(500);
          this.session.checkingSavingAccount.accountNumber = this.form.checkingSavingAccount.accountNumber;
          this.form.checkingSavingAccount.accountNumber = "";
          this.session.checkingSavingAccount.cifIdName = this.form.checkingSavingAccount.cifIdName;
          this.form.checkingSavingAccount.cifIdName = "";
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        } finally {
          await this.simpleWait(500);
          this.property.animation.CheckingSavingAccount.isLoading = false;
        }
      }
    },
    clearModalResultCheckingSavingAccount() {
      this.table.data.savingAccount = [];
      this.property.listElement.savingAccount.rows = 0;
      this.property.listElement.savingAccount.currentPage = 1;
      this.property.listElement.savingAccount.perPage = 5;
    },
    async changePaginationSavingAccount(event) {
      this.table.data.savingAccount = [];
      this.property.listElement.savingAccount.downloading = true;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "saving-account/cross-office/simple-list",
          payload: {
            accountNumber: this.session.checkingSavingAccount.accountNumber,
            idCardNumber: "",
            cifIdName: this.session.checkingSavingAccount.cifIdName,
            page: event - 1,
            size: this.property.listElement.savingAccount.perPage,
          },
        });
        await this.simpleWait(500);
        if (resp.data.code === "SUCCESS") {
          this.table.data.savingAccount = resp.data.data.content;
          this.property.listElement.savingAccount.rows =
            resp.data.data.totalElements;
        } else {
          this.failedGetSavingAccount(resp);
        }
      } catch (error) {
        await this.simpleWait(500);
        this.handleErrorGetSavingAccount(error);
      } finally {
        await this.simpleWait(500);
        this.property.listElement.savingAccount.downloading = false;
      }
    },
    failedGetSavingAccount(resp) {
      this.table.data.savingAccount = [];
      this.property.listElement.sav;
      ingAccount.rows = 0;
      this.property.listElement.savingAccount.message = resp.data.message;
    },
    Moment: Moment,
    resetBookTransaction() {
      this.property.filterDto.startRow = 0;
      this.property.filterDto.balanceEndRow = 0;
      this.property.filterDto.endRow = 33;
      this.table.data.pdf = [];
      this.table.data.transaction = [];
    },
    async getSavingAccountBookTransaction(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.property.listElement.bookTransaction.downloading = true;
        this.resetBookTransaction();
        try {
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "report",
            reqUrl: "book-transaction",
            payload: {
              accountNumber: props.row.accountNumber,
              dateFrom: this.property.filterDto.dateFrom,
              dateTo: this.property.filterDto.dateTo,
            },
          });
          if (resp.data.code === "SUCCESS") {
            this.property.filterDto.accountNumber = props.row.accountNumber;
            this.property.modal.showModalResultListSavingAccount = false;
            this.clearModalResultCheckingSavingAccount();
            await this.simpleWait(500);
            this.identity.accountNumber = resp.data.data.header.accountNumber;
            this.identity.nama = `${
              resp.data.data.header.qqName
                ? `${resp.data.data.header.name} ${resp.data.data.header.qqCode} ${resp.data.data.header.qqName}`
                : `${resp.data.data.header.name}`
            }`;
            this.identity.saldoTerakhir = resp.data.data.header.balance;
            if (resp.data.data.detail) {
              this.table.data.transaction = resp.data.data.detail;
              this.setupLastBalanceAndRow();
            } else {
              this.table.data.transaction = [];
              this.property.listElement.bookTransaction.message =
                "Tidak Ada Transaksi";
            }
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        } finally {
          await this.simpleWait(500);
          this.property.listElement.bookTransaction.downloading = false;
        }
      }
    },
    setupLastBalanceAndRow() {
      this.table.data.transaction.map((data, index) => {
        if (index === 32) {
          this.property.filterDto.balanceEndRow = data.balanceEnd;
        }
      });
    },
    changeStarRow() {
      // this.table.data.pdf = [];
      this.table.data.transaction.map((data, index) => {
        if (index <= this.property.filterDto.endRow - 1) {
          if (index >= this.property.filterDto.startRow) {
            // this.table.data.pdf.push(data);
          }
          // const PDFDto = {
          //   transactionDate: data.transactionDate,
          //   nominal: data.nominal,
          //   trxCodeAndDescription: data.trxCodeAndDescription,
          //   mutationPosition: data.mutationPosition,
          //   balanceEnd: data.balanceEnd,
          //   nominalCredit: data.nominalCredit,
          //   nominalDebit: data.nominalDebit,
          //   transactionCodeBook: data.transactionCodeBook,
          //   createBy: data.createBy,
          // };
          // this.table.data.pdf.push(
          //   index >= this.property.filterDto.startRow - 1 ? data : PDFDto
          // );
        }
      });
    },
    changeEndRow() {
      // this.table.data.pdf = [];
      this.table.data.transaction.map((data, index) => {
        if (index === this.property.filterDto.endRow - 1) {
          this.property.filterDto.balanceEndRow = data.balanceEnd;
        }
        if (index <= this.property.filterDto.endRow - 1) {
          // if (index >= this.property.filterDto.startRow) {
          //   this.table.data.pdf.push(data);
          // }
          // const PDFDto = {
          //   transactionDate: data.transactionDate,
          //   nominal: data.nominal,
          //   trxCodeAndDescription: data.trxCodeAndDescription,
          //   mutationPosition: data.mutationPosition,
          //   balanceEnd: data.balanceEnd,
          //   nominalCredit: data.nominalCredit,
          //   nominalDebit: data.nominalDebit,
          //   transactionCodeBook: data.transactionCodeBook,
          //   createBy: data.createBy,
          // };
          // this.table.data.pdf.push(
          //   index >= this.property.filterDto.startRow - 1 ? data : PDFDto
          // );
        }
      });
    },
    printBookTransaction() {
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "in",
        format: "letter",
      });
      var startHeight = 1;
      this.table.data.pdf.map((data, index) => {
        doc
          .setFontSize(8)
          .text(
            `${
              data.transactionDate
                ? this.Moment(data.transactionDate).format("DD/MM/YY")
                : ""
            }`,
            0.3,
            startHeight
          );
        doc
          .setFontSize(8)
          .text(
            data.transactionCodeBook ? data.transactionCodeBook : "",
            1,
            startHeight
          );
        doc
          .setFontSize(8)
          .text(
            `${
              data.nominalDebit ? this.convertCurrency(data.nominalDebit) : ""
            }`,
            1.7,
            startHeight
          );
        doc
          .setFontSize(8)
          .text(
            `${
              data.nominalCredit ? this.convertCurrency(data.nominalCredit) : ""
            }`,
            2.5,
            startHeight
          );
        doc
          .setFontSize(8)
          .text(
            `${data.balanceEnd ? this.convertCurrency(data.balanceEnd) : ""}`,
            3.5,
            startHeight
          );
        doc
          .setFontSize(8)
          .text(`${data.createBy ? data.createBy : ""}`, 4.4, startHeight);
        startHeight = index === 13 ? startHeight + 0.6 : startHeight + 0.2;
        console.log(startHeight);
      });

      doc.autoPrint();

      const hiddFrame = document.createElement("iframe");
      hiddFrame.style.position = "fixed";
      // "visibility: hidden" would trigger safety rules in some browsers like safari，
      // in which the iframe display in a pretty small size instead of hidden.
      // here is some little hack ~
      hiddFrame.style.width = "1px";
      hiddFrame.style.height = "1px";
      hiddFrame.style.opacity = "0.01";
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        window.navigator.userAgent
      );
      if (isSafari) {
        // fallback in safari
        hiddFrame.onload = () => {
          try {
            hiddFrame.contentWindow.document.execCommand("print", false, null);
          } catch (e) {
            hiddFrame.contentWindow.print();
          }
        };
      }
      hiddFrame.src = doc.output("bloburl");
      document.body.appendChild(hiddFrame);
    },

    defaultMessageTable() {
      this.property.listElement.bookTransaction.message =
        "Pilih Nomor Rekening Terlebih Dahulu";
    },
    clearForm() {
      this.table.data.transaction = [];
      this.identity.nama = "";
      this.identity.saldoTerakhir = "";
    },
  },
  mounted() {
    this.validationDomain = window.location.hostname;
    this.defaultMessageTable();
  },
};
